export default {
    gestion: {
        pei:{
            alerts: {
                pei_effect_name: {
                    restricted_use: "Disponible limité",
                }
            },
            anomalies: {
                er: "Disponible limité",
            },
            anomaly_state: {
                er: 'Anomalies disponibles limitées'
            },
            etat_start: {
                emploi_restreint: 'Disponible limité',
            },
            type_hydrant: {
                poteau_relais_incendie: "Poteau relais incendie",
                PI80: 'Poteau incendie 80',
                PI100: 'Poteau incendie 100',
                PI150: 'Poteau incendie 150',
                BI80: 'Bouche incendie 80',
                BI100: 'Bouche incendie 100',
                REA: 'Réserve en eau alimentée',
                RENA: 'Réserve en eau non alimentée',
                PAP: 'Point d\'aspiration permanent',
                PAV: 'Point d\'aspiration variable',
                PUISARD: 'Puisard',
                CS: 'Colonne humide / sèche',

                legend: {
                    BI100_disponible: 'Bouche incendie 100 Disponible',
                    BI100_emploirestreint: 'Bouche incendie 100 Disponible limité',
                    BI100_indisponible: 'Bouche incendie 100 Indisponible',

                    BI80_disponible: 'Bouche incendie 80 Disponible',
                    BI80_emploirestreint: 'Bouche incendie 80 Disponible limité',
                    BI80_indisponible: 'Bouche incendie 80 Indisponible',

                    PAP_disponible: 'Point d\'aspiration permanent Disponible',
                    PAP_emploirestreint: 'Point d\'aspiration permanent Disponible limité',
                    PAP_indisponible: 'Point d\'aspiration permanent Indisponible',

                    PAV_disponible: 'Point d\'aspiration variable Disponible',
                    PAV_emploirestreint: 'Point d\'aspiration variable Disponible limité',
                    PAV_indisponible: 'Point d\'aspiration variable Indisponible',

                    PI100_disponible: 'Poteau incendie 100 Disponible',
                    PI100_emploirestreint: 'Poteau incendie 100 Disponible limité',
                    PI100_indisponible: 'Poteau incendie 100 Indisponible',

                    PI150_disponible: 'Poteau incendie 150 Disponible',
                    PI150_emploirestreint: 'Poteau incendie 150 Disponible limité',
                    PI150_indisponible: 'Poteau incendie 150 Indisponible',

                    PI80_disponible: 'Poteau incendie 80 Disponible',
                    PI80_emploirestreint: 'Poteau incendie 80 Disponible limité',
                    PI80_indisponible: 'Poteau incendie 80 Indisponible',

                    PUISARD_disponible: 'Puisard Disponible',
                    PUISARD_emploirestreint: 'Puisard Disponible limité',
                    PUISARD_indisponible: 'Puisard Indisponible',

                    REA_disponible: 'Réserve en eau alimentée Disponible',
                    REA_emploirestreint: 'Réserve en eau alimentée Disponible limité',
                    REA_indisponible: 'Réserve en eau alimentée Indisponible',

                    RENA_disponible: 'Réserve en eau non alimentée Disponible',
                    RENA_emploirestreint: 'Réserve en eau non alimentée Disponible limité',
                    RENA_indisponible: 'Réserve en eau non alimentée Indisponible',

                    poteaurelaisincendie_disponible: 'Poteau relais incendie Disponible',
                    poteaurelaisincendie_emploirestreint: 'Poteau relais incendie Disponible limité',
                    poteaurelaisincendie_indisponible: 'Poteau relais incendie Indisponible',

                    
                }
            },
 
        },
    }
}
